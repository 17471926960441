<template>
    <div class="GameType">
        <MenuBar @toggleMenu="openLeftMenu"/>
        <!--<MenuBar v-if="!embedMode" @openGame="openGame" @toggleMenu="openLeftMenu"/>-->
        <transition name="slide">
            <MenuLeft @close="closeLeftMenu" v-show="showMenuLeft"></MenuLeft>
        </transition>
        <div class="box-slide-menu">
            <div><span class="active">ติดตาม</span></div>
            <div><span>ประเด็นร้อน</span></div>
            <div><span>เซเลป</span></div>
            <div><span>ใหม่</span></div>
        </div>
        <div class="content-page box-live-stream">

            <div class="box-thumb-room">
                <div class="box-room room-1">
                    <div class="box-type-room">[90s]</div>
                    <div class="box-name-room">
                        <p>[90s] Mymint Op</p>
                        <div class="box-room-child">
                            <div><span class="level-room">1</span> From M Star</div>
                            <div><span class="n-room"><i class="fa fa-user"></i> 3738</span></div>
                        </div>
                    </div>
                </div>
                <div class="box-room room-2">
                    <div class="box-type-room">[20MClub]</div>
                    <div class="box-name-room">
                        <p>Hello Milky</p>
                        <div class="box-room-child">
                            <div><span class="level-room level-orange">2</span> From M Star</div>
                            <div><span class="n-room"><i class="fa fa-user"></i> 1123</span></div>
                        </div>
                    </div>
                </div>
                <div class="box-room room-3">
                    <div class="box-type-room">88Angel</div>
                    <div class="box-name-room">
                        <p>Nana World</p>
                        <div class="box-room-child">
                            <div><span class="level-room level-blue">3</span> From M Star</div>
                            <div><span class="n-room"><i class="fa fa-user"></i> 1055</span></div>
                        </div>
                    </div>
                </div>
                <div class="box-room room-4">
                    <div class="box-name-room">
                        <p>OBK LALALA</p>
                        <div class="box-room-child">
                            <div> Ban chang</div>
                            <div><span class="n-room"><i class="fa fa-user"></i> 2523</span></div>
                        </div>
                    </div>
                </div>
                <div class="box-room room-5">
                    <div class="box-type-room">M Team</div>
                    <div class="box-name-room">
                        <p>BOBO NO</p>
                        <div class="box-room-child">
                            <div> Ban chang</div>
                            <div><span class="n-room"><i class="fa fa-user"></i> 93</span></div>
                        </div>
                    </div>
                </div>
                <div class="box-room room-6">
                    <div class="box-type-room">Hot</div>
                    <div class="box-name-room">
                        <p>fayray R.</p>
                        <div class="box-room-child">
                            <div> Ban chang</div>
                            <div><span class="n-room"><i class="fa fa-user"></i> 2445</span></div>
                        </div>
                    </div>
                </div>
                <div class="box-lg-ads w-100"></div>
                <div class="box-room room-6">
                    <div class="box-type-room">Hot</div>
                    <div class="box-name-room">
                        <p>fayray R.</p>
                        <div class="box-room-child">
                            <div> Ban chang</div>
                            <div><span class="n-room"><i class="fa fa-user"></i> 2445</span></div>
                        </div>
                    </div>
                </div>
                <div class="box-room room-5">
                    <div class="box-type-room">Hot</div>
                    <div class="box-name-room">
                        <p>fayray R.</p>
                        <div class="box-room-child">
                            <div> Ban chang</div>
                            <div><span class="n-room"><i class="fa fa-user"></i> 2445</span></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box-bottom-menu">
                <div><span><i class="far fa-heart"></i></span></div>
                <div><span><i class="far fa-comment"></i> </span></div>
                <div><span><i class="fas fa-camera"></i></span></div>
                <div><span><i class="fas fa-map-marker-alt"></i></span></div>
                <div><span><i class="far fa-user"></i> </span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuBar from '@/components/MenuBar'
    import MenuLeft from '@/components/MenuLeft'
    import LayoutMixin from '@/mixins/Layout'
    import GameMixin from '@/mixins/Game'

    export default {
        name: 'GameType',
        components: {
            MenuBar,
            MenuLeft,
        },
        mixins: [
            LayoutMixin,
            GameMixin,
        ],
        methods: {
            play(table) {
                this.selectGameTable(table)
            },
        },
    }
</script>

<style scoped src="@/assets/css/live.css"></style>
<style scoped>

    .box-live-stream {
        position: fixed;
        height: 72vh;
        width: 100%;
        padding: 0px;
        padding-bottom: 50px;
        overflow-y: auto;
    }

    .box-room {
        display: inline-block;
        border: solid 2px #fff;
        height: 170px;
        border-radius: 5px;
        width: 50%;
        position: relative;
        background-size: cover;
        background-position: center;
        margin: 0px;
        padding: 0px;
        float: left;
    }

    .room-1 {
        background-image: url(../assets/images/live-stream/woman.jpg);
    }

    .room-2 {
        background-image: url(../assets/images/live-stream/2.jpg);
    }

    .room-3 {
        background-image: url(../assets/images/live-stream/3.jpg);
    }

    .room-4 {
        background-image: url(../assets/images/live-stream/4.jpg);
    }

    .room-5 {
        background-image: url(../assets/images/live-stream/5.jpg);
    }

    .room-6 {
        background-image: url(../assets/images/live-stream/6.jpg);
    }

    .box-name-room {
        position: absolute;
        bottom: 0;
        padding: 5px 10px;
        width: 100%;
        color: #fff;
        font-family: "Athiti", sans-serif;
        text-shadow: 1px 0px #888;
        line-height: 20px;
        background-color: rgba(0, 0, 0, 0.08);
    }

    .box-name-room p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0px;
        padding: 0px;
    }

    .box-room-child {
        width: 100%;
        display: flex;
        font-size: 12px;
    }

    .box-room-child div:first-child {
        flex: 3;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .box-room-child div:last-child span.n-room {
        font-weight: 600;
    }

    .level-room {
        display: inline-block;
        padding: 1px 8px;
        font-size: 12px;
        text-align: center;
        line-height: 14px;
        border-radius: 10px;
        background-color: #e96fdb;
        box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;
        font-weight: 600;
        border: solid 1px #eee;
    }

    .level-orange {
        background-color: #f8bb37;
    }

    .level-blue {
        background-color: #2f9fff;
    }

    .box-type-room {
        position: absolute;
        top: 3px;
        right: 3px;
        color: #fff;
        padding: 1px 10px;
        font-size: 11px;
        border-radius: 10px;
        background: linear-gradient(to right, #753189 0%, #d20a84 100%) !important;
        font-family: "Athiti", sans-serif;
    }

    .box-thumb-room{
        clear: both;
        /*height: 100%;*/
        display: block;
        /*position: absolute;*/
        /*width: 100%;*/
        /*left: 0px;*/
        /*transition: 1s;*/
    }

    .box-slide-menu {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        align-items: center;
        padding: 2px 5px;
        width: 100%;
        background-color: white;
        font-family: "Athiti", sans-serif;
    }

    .box-slide-menu div{
        padding: 10px 8px;
    }

    .box-slide-menu span {
        font-weight: bold;
        color: #888;
    }

    .box-slide-menu span.active {
        color: #000;
        padding: 0px 8px;
        background-image: url(../assets/images/sticker/pink-heart.png);
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: top right;
        border-bottom: solid 2px #f7daf0;
    }

    .box-bottom-menu{
        position: fixed;
        display: flex;
        bottom: 0;
        background-color: white;
        padding: 8px;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .box-bottom-menu div{
        width: 25%;
        text-align: center;
    }

    .box-bottom-menu div > span{
        display: block;
        width: 35px;
        height: 25px;
        margin: 0 auto;
        border: solid 1px #ccc;
        padding: 0px 5px;
        border-radius: 5px;
    }

    .box-lg-ads{
        display: block;
        height: 120px;
        background-image: url(../assets/images/1.jpg);
        background-size: cover;
        float: left;
    }

</style>